import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    users as usersApi,
    showUser as showUserApi,
    addUser as addUserApi,
    updateUser as updateUserApi,
    deleteUser as deleteUserApi,
} from '../../helpers';

// actions
import { userApiResponseSuccess, userApiResponseError } from './actions';

// constants
import { UserActionTypes } from './constants';

interface UserData {
    payload: {
        id: string,
        hasSubscribed: boolean,
        sortBy: string,
        page: number,
        limit: number,
        name: string,
        username: string,
        password: string,
        role: string,
        status: string,
    };
    type: string;
}

/**
 * User list
 */
function* userList({ payload: {
    sortBy,
    limit,
    page
} }: UserData): SagaIterator {
    try {

        const response = yield call(usersApi, {
            sortBy,
            limit,
            page
        });

        const responseData = {
            users: response.data.users,
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        yield put(userApiResponseSuccess({
            actionType: UserActionTypes.USER_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(userApiResponseError(UserActionTypes.USER_LIST, error));

    }
}

/**
 * Show User
 */
function* showUser({ payload: {
    id,
    sortBy,
    limit,
    page
} }: UserData): SagaIterator {
    try {

        const response = yield call(showUserApi, {
            sortBy,
            limit,
            page
        }, id);
        const responseData = {
            data: response.data,
        }
        yield put(userApiResponseSuccess({
            actionType: UserActionTypes.SHOW_USER,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(userApiResponseError(UserActionTypes.SHOW_USER, error));

    }
}

/**
 * Add MisLink
 */
function* addUser({ payload: {
    name,
    username,
    password,
    role,
    status } }: UserData): SagaIterator {
    try {

        const response = yield call(addUserApi, {
            name,
            username,
            password,
            role,
            status
        });
        const responseData = {
            data: response.data.data,
        }
        yield put(userApiResponseSuccess({
            actionType: UserActionTypes.ADD_USER,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(userApiResponseError(UserActionTypes.ADD_USER, error));

    }
}

/**
 * Update MisLink
 */
function* updateUser({ payload: {
    id,
    name,
    username,
    password,
    role,
    status } }: UserData): SagaIterator {
    try {

        const response = yield call(updateUserApi, {
            name,
            username,
            password,
            role,
            status
        }, id);
        const responseData = {
            data: response.data.data,
        }
        yield put(userApiResponseSuccess({
            actionType: UserActionTypes.UPDATE_USER,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(userApiResponseError(UserActionTypes.UPDATE_USER, error));

    }
}


/**
 * Delete MisLink
 */
// function* deleteUser({ payload: { id } }: FormData): SagaIterator {
//     try {
//         yield call(deleteUserApi, id);
//         const responseData = {
//             deletedId: id
//         };
//         yield put(misLinkApiResponseSuccess({
//             actionType: MisLinkActionTypes.DELETE_MIS_LINK,
//             responseData
//         }));
//     } catch (error: any) {
//         yield put(misLinkApiResponseError(MisLinkActionTypes.DELETE_MIS_LINK, error));

//     }
// }


/**
 * Delete User
 */
function* deleteUser({ payload: { id } }: UserData): SagaIterator {
    try {
        yield call(deleteUserApi, id);
        const responseData = {
            deletedId: id
        };
        yield put(userApiResponseSuccess({
            actionType: UserActionTypes.DELETE_USER,
            responseData
        }));
    } catch (error: any) {
        yield put(userApiResponseError(UserActionTypes.DELETE_USER, error));

    }
}


export function* watchUserList() {
    yield takeEvery(UserActionTypes.USER_LIST, userList);
}


export function* watchAddUser() {
    yield takeEvery(UserActionTypes.ADD_USER, addUser);
}

export function* watchUpdateUser() {
    yield takeEvery(UserActionTypes.UPDATE_USER, updateUser);
}

export function* watchShowUser() {
    yield takeEvery(UserActionTypes.SHOW_USER, showUser);
}

export function* watchDeleteUser() {
    yield takeEvery(UserActionTypes.DELETE_USER, deleteUser);
}

function* userSaga() {
    yield all([fork(watchUserList), fork(watchAddUser), fork(watchUpdateUser), fork(watchShowUser), fork(watchDeleteUser)]);
}

export default userSaga;
