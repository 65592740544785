import { APICore } from './apiCore';
const api = new APICore();

// user
// eslint-disable-next-line no-empty-pattern


function users(params: {
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/users`;
    return api.get(`${baseUrl}`, params);

}
function showUser(params: {
    sortBy: string,
    limit: number,
    page: number,
}, id: string) {
    const baseUrl = `/users/${id}`;
    return api.get(`${baseUrl}`, params);
}

function addUser(params: {
    name: string,
    username: string,
    status: string,
    role: string,
    password: string
}) {
    const baseUrl = `/users`;
    return api.create(`${baseUrl}`, params);
}

function updateUser(params: {
    name: string,
    username: string,
    status: string,
    role: string,
    password: string
}, id: string) {
    const baseUrl = `/users/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteUser(id: string) {
    const baseUrl = `/users/${id}`;
    return api.delete(`${baseUrl}`);
}


export { users, showUser, addUser, updateUser, deleteUser };
