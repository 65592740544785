// constants
import { UserActionTypes } from './constants';

export interface UserActionType {
    type:
    | UserActionTypes.API_RESPONSE_SUCCESS
    | UserActionTypes.API_RESPONSE_ERROR
    | UserActionTypes.USER_LIST
    | UserActionTypes.ADD_USER
    | UserActionTypes.UPDATE_USER
    | UserActionTypes.SHOW_USER
    | UserActionTypes.DELETE_USER
    payload: {} | string;
}


interface ResponseData {
    actionType: string,
    responseData: any,
}
// common success
export const userApiResponseSuccess = ({ actionType, responseData }: ResponseData): UserActionType => ({
    type: UserActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const userApiResponseError = (actionType: string, error: string): UserActionType => ({
    type: UserActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

interface FiltersData {
    id: string,
    sortBy: string,
    limit: number,
    page: number,
};

interface FormData {
    id: string,
    name: string,
    username: string,
    status: string,
    role: string,
    password: string
};

export const getUser = ({
    sortBy,
    limit,
    page,
}: FiltersData): UserActionType => ({
    type: UserActionTypes.USER_LIST,
    payload: {
        sortBy,
        limit,
        page,
    },
});
export const showUser = ({
    id,
    sortBy,
    limit,
    page,
}: FiltersData): UserActionType => ({
    type: UserActionTypes.SHOW_USER,
    payload: {
        id,
        sortBy,
        limit,
        page,
    },
});

export const deleteUser = (id: string): UserActionType => ({
    type: UserActionTypes.DELETE_USER,
    payload: { id },
});

export const createUser = ({
    name,
    username,
    password,
    role,
    status,
}: FormData): UserActionType => ({
    type: UserActionTypes.ADD_USER,
    payload: {
        name,
        username,
        role,
        password,
        status,
    },
});

export const updateUser = ({
    id,
    name,
    username,
    password,
    role,
    status,
}: FormData): UserActionType => ({
    type: UserActionTypes.UPDATE_USER,
    payload: {
        id,
        name,
        username,
        role,
        password,
        status,
    },
});

// export const destroyMisLink = (id: string): UserActionType => ({
//     type: UserActionTypes.DELETE_USER,
//     payload: { id },
// });






